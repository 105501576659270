<template>
  <div>
    <main class="router-view-container">
      <div class="flex items-center space-x-4 mb-5">
        <vs-button @click="$router.go(-1)" border size="large">
          <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
        </vs-button>
        <h1 class="page_title">{{ $route.meta.title }}</h1>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'giftVoucher-history',
  data() {
    return {
      currentPage: 1,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    // 3.2.3.3 讀取會員購物金列表
    getData() {
      // this.$axios({
      //   url: 'front/store/point1/getPoint1List.php',
      //   headers: {
      //     stoken: this.$store.state.login.userInfo.stoken,
      //   },
      //   kcval: true,
      //   data: {
      //     lang: 0,
      //     page: this.currentPage,
      //     smno: '',
      //   },
      // }).then((res) => {
      //   console.log(res)
      //   if (res.data.Success) {
      //   } else {
      //     this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
      //   }
      // })
    },
  },
}
</script>

<style></style>
